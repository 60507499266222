<template>
    <div class="Table" ref="invitationSentTableComponent">
      <b-table
        :items="this.invitationList"
        :fields="columns"
        hover
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        selectable
        select-mode="single"
        :lang="this.$i18n.locale"
        @row-selected="onRowSelected"
        ref="requestTableComponent"
      >
        <template #cell(date)="data">
          <span class="text__sr text-secondary">{{ data.item.date | formatDate }}</span>
        </template>
        <template #cell(name)="data">
          <span class="text__sr text-secondary">{{
              data.item.patientName ? data.item.patientName : null
            }}</span>
        </template>
        <template #cell(origin)="data">
          <span class="text__sr text-secondary">{{
              getOrigin(data.item.origin ? data.item.origin : null)
            }}</span>
        </template>
        <template #cell(specialty)="data">
          <span class="text__sr text-secondary">{{
              data.item.specialty ? data.item.specialty.name : null
            }}</span>
        </template>
        <template #cell(procedure)="data">
          <span class="text__sr text-secondary">{{ data.item.procedure }}</span>
        </template>
        <template #cell(invited)="data">
          <IconGroup
            v-if="data.item.invited"
            :icon="getClinicAvatar(data.item.invited)"
            :label="data.item.invited ? data.item.invited.name : null"
          />
        </template>
        <template #cell(stage)="data">
          <span class="text__sr text-secondary">
            {{ getRequestStage(data.item.stage) }}
          </span>
        </template>
        <template #cell(status)="data">
          <Badge
            v-if="data.item.status"
            :variant="data.item.status"
          >
            {{ getVariantByStatus(data.item.status) }}
          </Badge>
        </template>
        <template #cell(actions)="data">
          <b-button
            v-if="(data.item.status === 'pending')"
            @click.prevent="handleCancel(data.item.id)"
            variant="secondary"
            size="sm"
          >
            {{$i18n.t('btn_cancel')}}
          </b-button>
        </template>
      </b-table>
    </div>
  </template>

<script>
// import IconActionsGroup from '@/components/atoms/Icon/IconActionsGroup/IconActionsGroup.vue';
import IconGroup from '@/components/atoms/Icon/IconGroup/IconGroup.vue';
import Badge from '@/components/atoms/Badge/Badge.vue';
import {
  getImageFormatted,
  getVariantByStatus,
  STAGE_OPTIONS,
  STATUS,
} from '@/helpers/ComponentHelper';
import { getAvatarByPathRequest } from '@/helpers/UserHelper';

export default {
  props: {
    invitations: {
      type: Array,
      default: () => [],
    },
    showActions: {
      type: Boolean,
      default: true,
    },
    canDelete: {
      type: Boolean,
      default: true,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
    canCopy: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      sortBy: 'case',
      sortDesc: false,
      status: STATUS,
      invitationList: this.invitations,
    };
  },
  methods: {
    onRowSelected(items) {
      if (items.length > 0) {
        this.$emit('handleSelectRow', items[0]);
      }
    },
    showUpdateForm(data) {
      this.$emit('showUpdateForm', data);
    },

    showCopyForm(data) {
      this.$emit('showCopyForm', data);
    },

    showDeleteModal(data) {
      this.$emit('showDeleteModal', data);
    },

    handleCancel(data) {
      this.$emit('handleCancel', data);
    },

    getImage(image) {
      return getImageFormatted(image);
    },

    getOrigin(origin) {
      return origin !== null ? origin.name : null;
    },

    getClinicAvatar(clinic) {
      return getAvatarByPathRequest(clinic);
    },

    getDoctorAvatar(doctor) {
      return getAvatarByPathRequest(doctor);
    },

    getVariantByStatus(status) {
      return this.$i18n.t(getVariantByStatus(status));
    },

    getRequestStage(data) {
      if (!data) return null;

      let stageName;

      if (typeof data === 'string') {
        const stageOption = STAGE_OPTIONS.filter((stage) => stage.value === data);
        stageName = this.$i18n.t(stageOption[0].text);

        return stageName;
      }

      const stageOption = STAGE_OPTIONS.filter((stage) => stage.value === data.id);
      stageName = this.$i18n.t(stageOption[0].text);

      return stageName;
    },
  },
  computed: {
    columns() {
      return [
        {
          key: 'caseNumber',
          label: this.$i18n.t('lb_instance'),
          sortable: true,
        },
        {
          key: 'date',
          label: this.$i18n.t('lb_sent'),
          sortable: true,
        },
        {
          key: 'name',
          label: this.$i18n.t('lb_name'),
          sortable: true,
        },
        {
          key: 'origin',
          label: this.$i18n.t('lb_origin'),
          sortable: true,
        },
        {
          key: 'specialty',
          label: this.$i18n.t('lb_specialty'),
          sortable: true,
        },
        {
          key: 'procedure',
          label: this.$i18n.t('lb_procedure'),
          sortable: true,
        },
        {
          key: 'invited',
          label: this.$i18n.t('lb_invited'),
          sortable: true,
        },
        {
          key: 'stage',
          label: this.$i18n.t('lb_stage'),
          sortable: true,
        },
        {
          key: 'status',
          label: this.$i18n.t('lb_status'),
          sortable: true,
        },
        {
          key: 'actions',
          label: '',
        },
      ];
    },
    getFields() {
      let newFields;

      if (this.isDoctor || this.isClinic) {
        newFields = this.fields.filter((el) => el.key !== 'clinic');

        return newFields;
      }

      if (!(this.isDoctor || this.isClinic)) {
        newFields = this.fields.filter((el) => el.key !== 'doctor');

        return newFields;
      }

      if (this.showActions) {
        newFields.push({ key: 'actions', label: '' });
      }

      return newFields;
    },

    isDoctor() {
      return this.$store.getters['user/isDoctor'];
    },

    isClinic() {
      return this.$store.getters['user/isClinic'];
    },
  },
  watch: {
    invitationList(newValue) { this.invitationList = newValue; },
    sortBy(newValue) {
      this.$emit('handleSortAsc', newValue);
    },

    sortDesc(newValue) {
      if (newValue === true) {
        this.$emit('handleSortDesc', this.sortBy);
      } else {
        this.$emit('handleSortAsc', this.sortBy);
      }
    },
  },
  components: {
    IconGroup,
    Badge,
  },
};
</script>

  <style lang="sass" scoped>
  @import '../Table.sass'
  </style>
  <!---->
